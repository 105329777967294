import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { WIDGET_PRESET_IDS } from '../utils/constants';

const presets = {
  Desktop: WIDGET_PRESET_IDS.CARD,
  Tablet: WIDGET_PRESET_IDS.CARD,
  Mobile: WIDGET_PRESET_IDS.CARD,
};
export const addWidgetWrapper = async ({ editorSDK }: { editorSDK: any }) => {
  // INSTALLTION - COMMENT FOR ADD PANEL
  console.log('Running addWidget flow');

  await editorSDK.application.appStudioWidgets.addWidget('token', {
    widgetId: 'fc1463fb-015f-4f57-81c9-f73082f6c3be',
    installationType: 'closed' as WidgetInstallationType,
    presetIds: {
      style: WIDGET_PRESET_IDS.CARD,
      layout: WIDGET_PRESET_IDS.CARD,
    },
    scopedPresets: {
      desktop: {
        layout: presets.Desktop,
        style: presets.Desktop,
      },
      tablet: {
        layout: presets.Desktop,
        style: presets.Desktop,
      },
      mobile: {
        layout: presets.Mobile,
        style: presets.Mobile,
      },
    },
    layout: {
      width: 340,
      x: 0,
      y: 100,
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
        width: {
          type: 'px',
          value: 340,
        },
        minWidth: {
          type: 'px',
          value: 280,
        },
        hidden: false,
      },
      itemLayout: {
        id: presets.Desktop,
        alignSelf: 'start',
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'percentage',
            value: 10,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
      containerLayout: {
        id: '',
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
    },
  });
};
