import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'imagebg',
  props: {
    // Here you can define your widget props
    layout: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'card',
      description: 'Widget layout',
    },
    imageShape: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'square',
      description: 'Image shape',
    },
  },
  methods: {
    setImage: {
      params: [
        {
          name: 'url',
          description: 'Image url',
        },
      ],
      description: 'Set image by url',
      returnsDescription: 'This is a description of what the function returns',
    },
    // collapse: {
    //   params: [],
    //   description: 'Collapse image',
    //   returnsDescription: 'This is a description of what the function returns',
    // },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
