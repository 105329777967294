import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { components } from './components';

export const widgetPresetsData = [
  {
    // preset data
    name: 'card',
    id: 'variants-lhswxsj92',
    layout: {
      width: 320,
      height: 510, // Do not use in Studio!
    },
  },
  {
    name: 'overlap',
    id: 'variants-lkgooa98',
    layout: {
      width: 320,
      height: 510, // Do not use in Studio!
    },
  },
  {
    name: 'side',
    id: 'variants-lhzy4qns',
    layout: {
      width: 980,
      height: 340, // Do not use in Studio!
    },
  },
];

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = flowAPI.translations.t;
  widgetBuilder.set({
    displayName: t('app.widget.displayName'),
    nickname: t('app.widget.nickname'),
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('app.design.title'),
      // presetsTitle: 'Discover More Designs',
    });
    // FROM: https://dev.wix.com/docs/fed-guild/articles/blocks-bo-b/bo-b/editor-behavior-configuration/presets-in-bo-b
    // widgetDesignBuilder.setPresets(widgetPresetsData as any);

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder.set({
        label: t('app.design.background'),
      });

      tabBuilder.groups().set({
        roles: [components.mainCointainer.toString().replace('#', '')],
      });
    });
  });
};
