import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'all presets',
  props: {
    // Here you can define your widget props
    layout: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'card',
      description: 'Widget layout',
    },
    mobileLayout: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'default',
      description: 'Widget layout',
    },
    imageShape: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'square',
      description: 'Image shape',
    },
    strechSlot: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
      description: 'Stretch time slot',
    },
    numberOfSlots: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 3,
      description: 'Number of slots',
    },
    mobileNumberOfSlots: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 3,
      description: 'Number of slots',
    },
    serviceId: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'undefined',
      description: 'Service Id from widget',
    },
    locationId: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'undefined',
      description: 'Location Id from widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    buttonClicked: {
      description: 'Fired when Widget loaded',
    },
    changeLayout: {
      description: 'Change layout width',
    },
  },
});
