import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { components } from './components';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = flowAPI.translations.t;
  widgetBuilder.set({
    displayName: t('app.widget.badge.displayName'),
    nickname: t('app.widget.badge.nickname'),
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('app.design.title'),
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder.set({
        label: t('app.design.badge.tab'),
      });

      tabBuilder.groups().set({
        roles: [components.badge.toString().replace('#', '')],
      });
    });
  });
};
