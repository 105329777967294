export const WIDGET_IDS = {
  IMAGE: 'd4540e90-fae1-44bf-922f-17c65b413c9f-oqtrb',
  BADGE: 'd4540e90-fae1-44bf-922f-17c65b413c9f-m1qie',
  ALL_PRESETS: 'd4540e90-fae1-44bf-922f-17c65b413c9f-m81ua',
  MAIN_REPEATER: 'd4540e90-fae1-44bf-922f-17c65b413c9f-lcchp',
  INNER_REPEATER: 'd4540e90-fae1-44bf-922f-17c65b413c9f-s34zt',
};

export const DEFAULT_LOCALE = 'en-US';

export enum PLATFORM {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}

export enum WIDGET_WIDTH_CLASSIC {
  CARD_OVERLAP = 320,
  SIDE_BY_SIDE = 980,
}
export enum WIDGET_WIDTH_STUDIO {
  CARD_OVERLAP = 340,
  SIDE_BY_SIDE_TABLET = 768,
}

export enum WIDGET_PRESET_IDS {
  CARD = 'variants-lhswxsj92',
  OVERLAP = 'variants-lkgooa98',
  SIDE_BY_SIDE = 'variants-lhzy4qns',
}

export const HELP_ARTICLE_ID = '92b799cc-7840-4eae-9fc7-baccc19b6696';
