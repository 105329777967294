const COMPS_DATA = {
  image: { role: 'serviceImage', label: 'app.elements.image' },
  videoConferenceBadge: {
    role: 'videoConferenceBadge',
    label: 'app.elements.conference',
  },
  serviceName: { role: 'serviceName', label: 'app.elements.serviceTitle' },
  durationAndPriceBox: {
    role: 'serviceDurationAndPrice',
    label: 'app.elements.durationAndPrice',
  },
  divider: { role: 'line1', label: 'app.elements.divider' },
  timezone: { role: 'timezone', label: 'app.elements.timezone' },
  bookYourNextSession: {
    role: 'bookYourNextSession',
    label: 'app.elements.bookYourNextSession',
  },
  seeMoreDatesAndTimes: {
    role: 'seeMoreDatesAndTimes',
    label: 'app.elements.seeMoreDatesAndTimes',
  },
};

const CATEGORIES_IDS = {
  info: 'app.elements.title',
};

export const getCategoryData = (flowAPI) => {
  // The title was removed to avoid 2 headlines as Blocks headline cannot be removed
  // const t = flowAPI.translations.t;
  return [
    {
      id: CATEGORIES_IDS.info,
      title: '', // t(CATEGORIES_IDS.info)
    },
  ];
};

export const getElementsData = (flowAPI) => {
  const t = flowAPI.translations.t;

  return [
    {
      label: t(COMPS_DATA.image.label),
      identifier: { role: COMPS_DATA.image.role },
      categoryId: CATEGORIES_IDS.info,
      index: 0,
    },
    {
      label: t(COMPS_DATA.videoConferenceBadge.label),
      identifier: { role: COMPS_DATA.videoConferenceBadge.role },
      categoryId: CATEGORIES_IDS.info,
      index: 1,
    },
    {
      label: t(COMPS_DATA.serviceName.label),
      identifier: { role: COMPS_DATA.serviceName.role },
      categoryId: CATEGORIES_IDS.info,
      index: 2,
    },
    {
      label: t(COMPS_DATA.durationAndPriceBox.label),
      identifier: { role: COMPS_DATA.durationAndPriceBox.role },
      categoryId: CATEGORIES_IDS.info,
      index: 3,
    },
    {
      label: t(COMPS_DATA.divider.label),
      identifier: { role: COMPS_DATA.divider.role },
      categoryId: CATEGORIES_IDS.info,
      index: 4,
    },
    {
      label: t(COMPS_DATA.bookYourNextSession.label),
      identifier: { role: COMPS_DATA.bookYourNextSession.role },
      categoryId: CATEGORIES_IDS.info,
      index: 5,
    },
    {
      label: t(COMPS_DATA.timezone.label),
      identifier: { role: COMPS_DATA.timezone.role },
      categoryId: CATEGORIES_IDS.info,
      index: 6,
    },
    {
      label: t(COMPS_DATA.seeMoreDatesAndTimes.label),
      identifier: { role: COMPS_DATA.seeMoreDatesAndTimes.role },
      categoryId: CATEGORIES_IDS.info,
      index: 7,
    },
  ];
};
