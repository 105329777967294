const widgetPresetsData = [
  {
    // preset data
    id: 'variants-lhiu55u81',
    src: 'cardAndOverlap_circle',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'ariants-lj5exgrb',
    src: 'sideBySide_circle',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'variants-lhrmlzhi',
    src: 'cardAndOverlap_square',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'variants-lhx7jbpa',
    src: 'sideBySide_square',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
];

export const getWidgetManifest = (widgetBuilder, appData) => {
  console.log('appData:', appData);
  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: 'Widget Design',
      presetsTitle: 'Discover More Designs',
    });

    // widgetDesignBuilder.setPresets(widgetPresetsData as any);
  });

  // additional manifest configuration
};
