export enum components {
  noServicesText = '#text11',
  multiStateBox = '#multiStateBox1',
  mainCointainer = '#box3',
  serviceImage = '#serviceImage',
  imageC = '#imagec',
  vcBadge = '#videoConferenceBadge',
  serviceName = '#serviceName',
  serviceDurationAndPrice = '#serviceDurationAndPrice',
  dayText = '#text3',
  bookYourNextSession = '#bookYourNextSession',
  timezone = '#timezone',
  availabilityStateBox = '#multiStateBox2',
  noFutureAvailabilityText = '#noFutureAvailabilityText',
  mainRepeater = '#mainReapeater1',
  seeMoreDatesAndTimes = '#seeMoreDatesAndTimes',
}
